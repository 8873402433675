import React from 'react';
import { useMedia } from 'react-use';
import {
    HStack,
    VStack,
    Heading,
    Text,
    Image,
    Button,
    Center,
    SimpleGrid,
    useColorModeValue,
    Box,
    Spacer,
    useToast,
    Stack,
    Spinner,
    Tooltip,
} from '@chakra-ui/react';
import { rateFilm, getUserRating, getQuartileFilm, searchTmdbFilmById, createQuartileFilm } from '../api';
import Carousel from './Carousel';
import RateCarousel from './RateCarousel';
import { useNavigate, useParams } from 'react-router-dom';
import RateNav from './RateNav';
import MobileAltNav from './MobileRateNav';
import { useAuth } from './AuthContext';
import AddToPlaylistPopover from './AddToPlaylistPopover';
import MobileAddToPlaylistModal from './MobileAddToPlaylistModal';
import { FaShare } from 'react-icons/fa';


const FilmPage = (props) => {
  const { filmId } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);
  const [hasUserRated, setHasUserRated] = React.useState(false);
  const [quartileFilmData, setQuartileFilmData] = React.useState({});
  const [tmdbFilmData, setTmdbFilmData] = React.useState({});
  const [newPlaylistTitle, setNewPlaylistTitle] = React.useState('');
  const [isFilmCreated, setIsFilmCreated] = React.useState(false);
  const [isDocumentary, setIsDocumentary] = React.useState(false);

  const {loggedIn, userId} = useAuth();

  const { 
    setLoggedIn, 
    handleLogout, 
    customSwitchChecked, 
    setCustomSwitchChecked, 
    colorMode, 
    toggleColorMode ,
    // userId
    // year
    // filmId,
    // tmdbFilmId
    } = props;
  const { fullImgPath, title, year, imagePath } = tmdbFilmData;

  const trimmedYear = parseInt(year);
  const toast = useToast();
  const isMobile = useMedia('(max-width: 767px)');
  const navigate = useNavigate();

  const [qRating, setQRating] = React.useState(0);
  const [plotRating, setPlotRating] = React.useState(0);
  const [actingRating, setActingRating] = React.useState(0);
  const [cinematographyRating, setCinematographyRating] = React.useState(0);
  const [noveltyRating, setNoveltyRating] = React.useState(0);
  const [endingRating, setEndingRating] = React.useState(0);

  const bgColor = useColorModeValue('white', 'black');
  const borderColor = useColorModeValue("black", "white");
  const textColor = useColorModeValue("black", "white");
  const cardBgColor = useColorModeValue('pink.500', 'purple.700');
  const ratingBgColor = useColorModeValue('pink.500', 'purple.700');
  const selectedColor = useColorModeValue("purple.500", "purple.500");
  const selectedColorB = useColorModeValue("pink.400", "pink.400");


// use effect and function that can handle a film that didn't previously exist in the Q db
// if film doesn't exist in the db then it creates one passing in the required data but no rating yet,
// this will be useful and actually populate or db quicker just based on film searches instead on film ratings
const fetchOrCreateFilm = async (filmId, tmdbFilm) => {
    try {
      let filmData = await getQuartileFilm(filmId);
      if (!filmData) {
        const filmDetails = {
            filmTmdbId: filmId,
            filmTitle: tmdbFilm.title,
            filmYear: tmdbFilm.release_date ? tmdbFilm.release_date.split('-')[0] : '',
            tmdbImagePath: tmdbFilm.backdrop_path || tmdbFilm.poster_path,
            genres: tmdbFilm.genres.map(genre => ({ id: genre.id, name: genre.name })),
        };
        filmData = await createQuartileFilm(filmDetails);
      }
      setQuartileFilmData(filmData);
      setIsFilmCreated(true);

      // Check if the film is a documentary
      const isDocumentary = filmData.genres.some(genre => genre.id === 99);
      setIsDocumentary(isDocumentary);
    } catch (error) {
      console.error('Error fetching or creating film:', error);
    }
  };

  React.useEffect(() => {
    const loadFilmAndUserRatings = async () => {
      setIsLoading(true);
      try {
        const tmdbFilm = await searchTmdbFilmById(filmId);
        const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
        const defaultImage = 'https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
        const imagePath = tmdbFilm?.backdrop_path ? tmdbFilm?.backdrop_path : tmdbFilm?.poster_path;
        const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;
        const year = tmdbFilm.release_date ? tmdbFilm.release_date.split('-')[0] : '';
        setTmdbFilmData({ ...tmdbFilm, fullImgPath, imagePath, year });

        await fetchOrCreateFilm(filmId, tmdbFilm);

        if (loggedIn) {
          const rating = await getUserRating({
            filmTmdbId: filmId, user_id: userId
          });
          if (rating) {
            setHasUserRated(true);
            setQRating(rating.filmQRating);
            setPlotRating(rating.filmPlotRating);
            setActingRating(rating.filmActingRating);
            setCinematographyRating(rating.filmCinematographyRating);
            setNoveltyRating(rating.filmNoveltyRating);
            setEndingRating(rating.filmEndingRating);
          }
        }
      } catch (error) {
        console.error('Error loading film and user ratings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadFilmAndUserRatings();
  }, [loggedIn, filmId]);

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast({
        title: "Link Copied!",
        description: "The link to this film has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }, (err) => {
      console.error('Could not copy text: ', err);
      toast({
        title: "Error",
        description: "Failed to copy link. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const handleRateFilm = async () => {
      try {
            const qRating = (plotRating + actingRating + cinematographyRating + noveltyRating + endingRating) / 2;
            setQRating(qRating);
          await rateFilm({
              user_id: userId,
              filmTmdbId: filmId,
              filmTitle: title,
              filmYear: trimmedYear,
              filmQRating: qRating,
              filmPlotRating: plotRating,
              filmActingRating: actingRating,
              filmCinematographyRating: cinematographyRating,
              filmNoveltyRating: noveltyRating,
              filmEndingRating: endingRating,
              tmdbImagePath: imagePath,
          });
          const film = await getQuartileFilm(filmId);
          // TODO: Handle if film data is null, render no averages
          setQuartileFilmData(film);
          setHasUserRated(true);
          toast({
              title: 'Rating Submitted',
              description: "You've rated " + title + " a  " + qRating,
              status: 'success',
              duration: 9000,
              isClosable: true,
          })
      } catch (e) {
          console.log('ERROR rating film in rateFilmScene: ', e);
      }
  }

  const isRatingDisabled = () => {
      return (
          plotRating === 0 ||
          actingRating === 0 ||     
          cinematographyRating === 0 ||
          noveltyRating === 0 ||
          endingRating === 0
          // every rating has to be bigger than 0
      );
  }

  const renderLoginButton = (loggedIn) => {
      if (!loggedIn) {
          return (
              <Button
                  letterSpacing='wider'
                  bg={bgColor}
                  border='4px'
                  borderRadius={'14'}
                  borderColor={'qPurple.400'}
                  size ='lg'
                  fontFamily="buttonFont"
                  onClick={() => {
                      navigate('/login');
                  }}
                  >
                      Login to Rate
              </Button>

          );
      } else {
          return (
                  <Button
                      m='10'
                      letterSpacing='wider'
                      bg={bgColor}
                      border='4px'
                      borderRadius={'14'}
                      borderColor={'qPurple.400'}
                      size ='lg'
                      disabled={isRatingDisabled()}
                      onClick={() => {
                          handleRateFilm();
                      }}
                  >
                      {hasUserRated ? 'Update your rating' : 'Rate Film'}
                  </Button>
          );
      }
  }

  const formatRating = (rating) => {
    if (rating === 10) {
      return '10';
    } else {
      return rating?.toFixed(1);
    }
  };

  const renderRateTitle = (loggedIn) => {
      if (!isMobile) {
          if (!loggedIn) {
              return (
                  <Heading letterSpacing='wider' p='2' fontFamily={'buttonFont'} size={'2xl'}>
                      Rate
                  </Heading>

              );
          } else {
              return (
                <HStack width='40%' ml='8'>
                  <Heading letterSpacing='wider' p='2' fontFamily={'buttonFont'} size={'2xl'} color='pink.400'>
                      My Rating - {qRating}
                  </Heading>
                </HStack>
              );
          }
      } else {
          if (!loggedIn) {
              return (
                  <Box width='90%' textAlign={'left'}>
                      <Heading letterSpacing='wider' pt='2' fontFamily={'buttonFont'} size={'3xl'}>
                          Rate
                      </Heading>
                  </Box>
                  // <Heading letterSpacing='wider' p='2' fontFamily={'buttonFont'} size={'2xl'}>
                  //     Rate
                  // </Heading>

              );
          } else {
              return (
                  <Box width='90%' textAlign={'left'}>
                      <Heading letterSpacing='wider' pt='2' fontFamily={'buttonFont'} size={'3xl'} color='pink.400'>
                          My Rating
                      </Heading>
                  </Box>
                  
              );
          }
      }

  }

  if (isLoading) {
      return <Spinner />;
  }

  return (
      <>

        {!isMobile ? (
            <> 
              <RateNav
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                handleLogout={handleLogout}
                customSwitchChecked={customSwitchChecked}
                setCustomSwitchChecked={setCustomSwitchChecked}
                colorMode={colorMode}
                toggleColorMode={toggleColorMode}
                userId={userId}
              />
              <HStack   width='100%' height='100%' alignItems='flex-start'  paddingBottom='0' spacing='0' borderBottom={`2px solid ${borderColor}`}>

                  <HStack marginBottom={'-50px'} width={'100%'}>

                 {/* stack helps to format long ass titles to indent */}
                  <Stack direction={'row'} paddingLeft='30px' paddingTop='320px' color='gray.400' width='100%' spacing='0' alignItems="flex-start">
                     
                  </Stack>
                  
                  <Stack direction='column' width='140%' height='500px' alignItems={'end'} justify={'space-between'}  paddingRight={'4'} paddingTop={'3'}  >
                      <VStack alignItems={'end'}>
                          <Heading letterSpacing='wide' textAlign={'right'} fontFamily={'categoryFont'}  size='2xl'>
                              {title}
                          </Heading>
                          {trimmedYear &&
                              <Heading letterSpacing='wide' fontFamily='categoryFont' size='lg'>
                                  {trimmedYear}
                              </Heading>}
                      </VStack>
                      <Spacer />
                      <Heading  margin={'-5px'} height='200px' fontFamily={'numberFont'} fontSize={'200px'} >
                      {quartileFilmData ? formatRating(quartileFilmData.filmQRatingAverage) : '??'}
                          {/*{filmQRatingAverage}*/}
                      </Heading>
                  </Stack>

                  </HStack>
                  <VStack  borderLeft={`2px solid ${borderColor}`} width={'100%'}>
                      <Box height = '500px'>
                          <Image
                              src = {fullImgPath}
                              alt="Movie Pic Placeholder"
                              height='100%'
                              width='100%'
                              objectFit='cover'
                          />
                      </Box>

                  </VStack>

              </HStack>
            

              <HStack paddingTop ='30px' paddingBottom ='50px' width='100%' justifyContent='space-between' borderBottom={`2px solid ${borderColor}`}>
                  {/* AVG PLOT Rating*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>PLOT</Heading>
                          <Center
                              w='160px'
                              h='160px'
                              border='4px'
                              borderRadius={'14'}
                              borderColor={'qPurple.400'}
                          >
                              <VStack spacing='-4'>
                                  <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px'>
                                      {quartileFilmData ? quartileFilmData.filmPlotRatingAverage : '?'}
                                  </Box>
                                  <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                      {quartileFilmData ? 
                                          quartileFilmData.filmPlotRatingAverage === 4 ? "Well Above Average" :
                                          quartileFilmData.filmPlotRatingAverage === 3 ? "Above Average" :
                                          quartileFilmData.filmPlotRatingAverage === 2 ? "Below Average" :
                                          quartileFilmData.filmPlotRatingAverage === 1 ? "Well Below Average" :
                                          "Loading Rating" : "Not Yet Rated"}
                                  </Text>
                              </VStack>
                              
                          </Center>
                  </VStack>

                  {/*AVG Acting/Narration Rating*/}
                  <VStack width='200px'>
                      <Tooltip 
                        hasArrow 
                        label={`${isDocumentary ? 'Acting For Non-Documentaries' : 'Narration For Documentaries'}`} 
                        placement='top' 
                        bg='qTurquoise.500' 
                        fontSize='16px'
                        borderRadius='md'
                      >
                          <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>
                              {isDocumentary ? 'Narration' : 'Acting'}
                          </Heading>
                      </Tooltip>
                          <Center
                              w='160px'
                              h='160px'
                              border='4px'
                              borderRadius={'14'}
                              borderColor={'qPurple.400'}
                          >
                              <VStack spacing='-4'>
                                  <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px'>
                                      {quartileFilmData ? quartileFilmData.filmActingRatingAverage : '?'}
                                  </Box>
                                  <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                          {quartileFilmData ? 
                                              quartileFilmData.filmActingRatingAverage === 4 ? "Well Above Average" :
                                              quartileFilmData.filmActingRatingAverage === 3 ? "Above Average" :
                                              quartileFilmData.filmActingRatingAverage === 2 ? "Below Average" :
                                              quartileFilmData.filmActingRatingAverage === 1 ? "Well Below Average" :
                                              "Loading Rating" : "Not Yet Rated"}
                                  </Text>
                              </VStack>
                                  
                          </Center>
                  </VStack>

                  {/*AVG Cinematography Rating*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Cinematography</Heading>
                          <Center
                              w='160px'
                              h='160px'
                              border='4px'
                              borderRadius={'14'}
                              borderColor={'qPurple.400'}
                          >
                              <VStack spacing='-4'>
                                  <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px'>
                                      {quartileFilmData ? quartileFilmData.filmCinematographyRatingAverage : '?'}
                                  </Box>
                                  <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                          {quartileFilmData ? 
                                              quartileFilmData.filmCinematographyRatingAverage === 4 ? "Well Above Average" :
                                              quartileFilmData.filmCinematographyRatingAverage === 3 ? "Above Average" :
                                              quartileFilmData.filmCinematographyRatingAverage === 2 ? "Below Average" :
                                              quartileFilmData.filmCinematographyRatingAverage === 1 ? "Well Below Average" :
                                              "Loading Rating" : "Not Yet Rated"}
                                  </Text>
                              </VStack>
                          </Center>
                  </VStack>

                  {/*AVG Novelty*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Novelty</Heading>
                          <Center
                              w='160px'
                              h='160px'
                              border='4px'
                              borderRadius={'14'}
                              borderColor={'qPurple.400'}
                          >
                              <VStack spacing='-4'>
                                  <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px'>
                                      {quartileFilmData ? quartileFilmData.filmNoveltyRatingAverage : '?'}
                                  </Box>
                                  <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                          {quartileFilmData ? 
                                              quartileFilmData.filmNoveltyRatingAverage === 4 ? "Well Above Average" :
                                              quartileFilmData.filmNoveltyRatingAverage === 3 ? "Above Average" :
                                              quartileFilmData.filmNoveltyRatingAverage === 2 ? "Below Average" :
                                              quartileFilmData.filmNoveltyRatingAverage === 1 ? "Well Below Average" :
                                              "Loading Rating" : "Not Yet Rated"}
                                  </Text>
                              </VStack>
                          </Center>
                  </VStack>
                  {/*AVG Ending Rating*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Ending</Heading>
                          <Center
                              w='160px'
                              h='160px'
                              border='4px'
                              borderRadius={'14'}
                              borderColor={'qPurple.400'}
                          >
                              <VStack spacing='-4'>
                                  <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px'>
                                      {quartileFilmData ? quartileFilmData.filmEndingRatingAverage : '?'}
                                  </Box>
                                  <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                          {quartileFilmData ? 
                                              quartileFilmData.filmEndingRatingAverage === 4 ? "Well Above Average" :
                                              quartileFilmData.filmEndingRatingAverage === 3 ? "Above Average" :
                                              quartileFilmData.filmEndingRatingAverage === 2 ? "Below Average" :
                                              quartileFilmData.filmEndingRatingAverage === 1 ? "Well Below Average" :
                                              "Loading Rating" : "Not Yet Rated"}
                                  </Text>
                              </VStack>
                          </Center>
                  </VStack>
              </HStack>

              <VStack justifyContent='center' width="100%" pt="12" spacing='6'pb='16'>
                {loggedIn && isFilmCreated && (
                    <AddToPlaylistPopover filmId={filmId} userId={userId} />
                )}  
                  <Button
                      leftIcon={<FaShare />}
                      onClick={handleShare}
                    //   mt={4}
                      size='xs'
                      colorScheme="purple"
                    >
                      Share
                  </Button>
              </VStack>

              {renderRateTitle(loggedIn)}

              <HStack paddingTop={'30px'} width='100%' justifyContent='space-between' >
                  {/* TODO: Implement as button group? Also make this it's own component */}

                  {/*PLOT Rating*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>PLOT</Heading>
                      <SimpleGrid  columns={2}>
                          <Tooltip hasArrow label="Well Above Average" placement='top-start' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={plotRating === 4 ? 'qPurple.500' : ''}
                                  onClick={() => {setPlotRating(4)}}
                              >
                                  4
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={plotRating === 3 ? 'qPurple.500' : ''}
                                  onClick={() => {setPlotRating(3)}}
                              >
                                  3
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Below Average" placement='bottom-start' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={plotRating === 2 ? 'qPurple.500' : ''}
                                  onClick={() => {setPlotRating(2)}}
                              >
                                  2
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={plotRating === 1 ? 'qPurple.500' : ''}
                                  onClick={() => {setPlotRating(1)}}
                              >
                                  1
                              </Button>
                          </Tooltip>
                          
                      </SimpleGrid>
                  </VStack>

                  {/*Acting Rating*/}
                  <VStack width='200px'>
                      <Tooltip 
                        hasArrow 
                        label={`${isDocumentary ? 'Acting For Non-Documentaries' : 'Narration For Documentaries'}`} 
                        placement='top' 
                        bg='qTurquoise.500' 
                        fontSize='16px'
                        borderRadius='md'
                      >
                      <Heading  letterSpacing='wider' fontFamily='categoryFont' size={'md'}>{isDocumentary ? 'Narration' : 'Acting'}</Heading>
                      </Tooltip>
                      <SimpleGrid  columns={2}>
                          <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={actingRating === 4 ? 'qPurple.500' : ''}
                                  onClick={() => {setActingRating(4)}}
                              >
                                  4
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={actingRating === 3 ? 'qPurple.500' : ''}
                                  onClick={() => {setActingRating(3)}}
                              >
                                  3
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={actingRating === 2 ? 'qPurple.500' : ''}
                                  onClick={() => {setActingRating(2)}}
                              >
                                  2
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={actingRating === 1 ? 'qPurple.500' : ''}
                                  onClick={() => {setActingRating(1)}}
                              >
                                  1
                              </Button>
                          </Tooltip>
                      </SimpleGrid>
                  </VStack>

                  {/*Cinematography Rating*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>Cinematography</Heading>
                      <SimpleGrid  columns={2}>
                          <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={cinematographyRating === 4 ? 'qPurple.500' : ''}
                                  onClick={() => {setCinematographyRating(4)}}
                              >
                                  4
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={cinematographyRating === 3 ? 'qPurple.500' : ''}
                                  onClick={() => {setCinematographyRating(3)}}
                              >
                                  3
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={cinematographyRating === 2 ? 'qPurple.500' : ''}
                                  onClick={() => {setCinematographyRating(2)}}
                              >
                                  2
                              </Button>
                          </Tooltip>  
                          <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={cinematographyRating === 1 ? 'qPurple.500' : ''}
                                  onClick={() => {setCinematographyRating(1)}}
                              >
                                  1
                              </Button>
                          </Tooltip>
                      </SimpleGrid>
                  </VStack>

                  {/*Novelty*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>Novelty</Heading>
                      <SimpleGrid  columns={2}>
                          <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={noveltyRating === 4 ? 'qPurple.500' : ''}
                                  onClick={() => {setNoveltyRating(4)}}
                              >
                                  4
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={noveltyRating === 3 ? 'qPurple.500' : ''}
                                  onClick={() => {setNoveltyRating(3)}}
                              >
                                  3
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={noveltyRating === 2 ? 'qPurple.500' : ''}
                                  onClick={() => {setNoveltyRating(2)}}
                              >
                                  2
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>            
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={noveltyRating === 1 ? 'qPurple.500' : ''}
                                  onClick={() => {setNoveltyRating(1)}}
                              >
                                  1
                              </Button>
                          </Tooltip>
                      </SimpleGrid>
                  </VStack>
                  {/*Ending Rating*/}
                  <VStack width='200px'>
                      <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>Ending</Heading>
                      <SimpleGrid  columns={2}>
                          <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={endingRating === 4 ? 'qPurple.500' : ''}
                                  onClick={() => {setEndingRating(4)}}
                              >
                                  4
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Above Average" placement='top-end' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={endingRating === 3 ? 'qPurple.500' : ''}
                                  onClick={() => {setEndingRating(3)}}
                              >
                                  3
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Below Average" placement='bottom-end' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={endingRating === 2 ? 'qPurple.500' : ''}
                                  onClick={() => {setEndingRating(2)}}
                              >
                                  2
                              </Button>
                          </Tooltip>
                          <Tooltip hasArrow label="Well Below Average" placement='left' bg='qTurquoise.500' fontSize='18px' borderRadius='md'>            
                              <Button
                                  height='60px'
                                  width='60px'
                                  border='2px'
                                  background={endingRating === 1 ? 'qPurple.500' : ''}
                                  onClick={() => {setEndingRating(1)}}
                              >
                                  1
                              </Button>
                          </Tooltip>
                      </SimpleGrid>
                  </VStack>
              </HStack>

              <Box padding={'20px'}>
                  {renderLoginButton(loggedIn)}
              </Box>
              </>
              ) : (
                  // mobile design
                  <>
                  <Box width="100%" overflowX="hidden">
                  <VStack  width ="100%" align='center' marginTop="-8px" >
                      <MobileAltNav
                        setLoggedIn={setLoggedIn}
                        loggedIn={loggedIn}
                        handleLogout={handleLogout}
                        customSwitchChecked={customSwitchChecked}
                        setCustomSwitchChecked={setCustomSwitchChecked}
                        colorMode={colorMode}
                        toggleColorMode={toggleColorMode}
                        userId={userId}
                      />
              
                      <Spacer paddingTop='2'></Spacer>

                          {/* rate Mobile Film Card */}
                      <VStack  className= "rateMobileFilmCardVstack" align='start' >
                              <Image
                                  src = {fullImgPath}
                                  alt="Movie Pic Placeholder"
                                  className = "rateMobileFilmCardFormat"
                              />

                              <VStack className={"rateMobileQCardFormat"} bg={bgColor} >
                                  <Heading  class="rateMobileQCardNumberFormat" justify-content={"flex-end"}>
                                  {quartileFilmData ? formatRating(quartileFilmData.filmQRatingAverage) : '??'}
                                  </Heading>
                                  <Image
                                      src={process.env.PUBLIC_URL + "/QLogoTest1.png"}
                                    //  may need to change back to one below on production?
                                      //   src = "QLogoTest1.png"
                                      alt="q Logo "
                                      class = "rateMobileQCardIconFormat"
                                  />
                              </VStack>
                              <Heading class = "rateMobileQCardTitleFormat" >
                                  {title}
                              </Heading>
                              {trimmedYear && <Heading class ='rateMobileQCardYearFormat' >
                                  {trimmedYear}
                              </Heading>}
                      </VStack>

              
                      <Carousel
                      quartileFilmData ={quartileFilmData}
                      />
              
                      <Spacer paddingTop='6'></Spacer>


                      <VStack justifyContent='center' width="100%" pt="6" spacing='6'>
                        {loggedIn && isFilmCreated && (
                            <MobileAddToPlaylistModal filmId={filmId} userId={userId} />
                        )}  
                        <Button   
                            leftIcon={<FaShare />}
                            onClick={handleShare}
                            size='xs'
                            mt={4}
                            colorScheme="purple"
                        >
                            Share
                        </Button>
                    </VStack>

                    

                      <Text  fontFamily='paragraphFont' color='gray.500' fontSize='10px'>
                          Rate Below
                      </Text>

                      <Box borderTop="2px" marginTop='20px' width="100%" borderColor="gray.500" />
                      {renderRateTitle(loggedIn)}

                      <HStack width='90%' align='left'>
                          <Image
                              src = {fullImgPath}
                              alt="Movie Pic Placeholder"
                              width='60px'
                              height='60px'
                              backgroundSize='cover'
                              backgroundPosition={'center'}
                              border= '2px solid'
                              objectFit='cover'
                              borderRadius='15%'
                          />
                          <VStack align='left' spacing='.6'>
                              <Heading class = "smRateMobileQCardTitleFormat" >
                                  {title}
                              </Heading>
                              {trimmedYear && <Heading class ='smRateMobileQCardYearFormat' >
                                  {trimmedYear}
                              </Heading>}
                          </VStack>
                      </HStack>
                          
                      <RateCarousel
                          qRating={qRating}
                          plotRating={plotRating}
                          actingRating={actingRating}
                          cinematographyRating={cinematographyRating}
                          noveltyRating={noveltyRating}
                          endingRating={endingRating}
                          setQRating={setQRating}
                          setPlotRating={setPlotRating}
                          setActingRating={setActingRating}
                          setCinematographyRating={setCinematographyRating}
                          setNoveltyRating={setNoveltyRating}
                          setEndingRating={setEndingRating}
                      />

                      <VStack width='100%' pt='5' spacing='-4'>
                          <Text fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                      Select A Number
                          </Text>

                          <HStack pt='6' width='80%' justify={'space-around'} align='top'>
                              <VStack>
                                  <Text mb='-1' fontFamily='paragraphFont' color='qPurple.500' fontSize='12px'>
                                      4
                                  </Text>
                                  <Text fontFamily='paragraphFont' color='qPurple.500' fontSize='12px'>
                                      Well<br></br> Above <br></br>Average
                                  </Text>
                              </VStack>
                              <VStack>
                                  <Text mb='-1' fontFamily='paragraphFont' color='qPurple.400' fontSize='12px'>
                                      3
                                  </Text>
                                  <Text fontFamily='paragraphFont' color='qPurple.400' fontSize='12px'>
                                      Above <br></br> Average
                                  </Text>
                              </VStack>
                              <Box borderRight="2px" height="70px" borderColor="gray.500" />
                              <VStack>
                                  <Text mb='-1' fontFamily='paragraphFont' color='qPurple.300' fontSize='12px'>
                                      2
                                  </Text>
                                  <Text fontFamily='paragraphFont' color='qPurple.300' fontSize='12px'>
                                      Below <br></br> Average
                                  </Text>
                              </VStack>
                              <VStack>
                                  <Text mb='-1' fontFamily='paragraphFont' color='qPurple.200' fontSize='12px'>
                                      1
                                  </Text>
                                  <Text fontFamily='paragraphFont' color='qPurple.200' fontSize='12px'>
                                      Well <br></br>Below <br></br>Average
                                  </Text>
                              </VStack>
                          </HStack>
                      </VStack>
                        

                      <Spacer paddingTop='2'></Spacer>
                                          
                      <VStack  class={"myRateQCardFormat "} >
                          <Heading  class="myRateQCardNumberFormat"  justify-content={"flex-end"} >
                          {/* {quartileFilmData ? quartileFilmData.filmQRatingAverage?.toFixed(1) : '??'} */}
                          {qRating ? formatRating(qRating) : '??'}                            
                          </Heading>
                          <Image
                              src={process.env.PUBLIC_URL + "/QLogoTest1.png"}
                              //  may need to change back to one below on production?
                                //   src = "QLogoTest1.png"
                              alt="q Logo "
                              class = "myRateQCardIconFormat"
                          />
                      </VStack>

                      <Box padding={'20px'} pb='20'>
                          {renderLoginButton(loggedIn)}
                          
                      </Box>
                      </VStack>
                      </Box>
                  </>
                  
              )}
      </>
  );
};

export default FilmPage;